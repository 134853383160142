import React, { useMemo } from 'react';
import { Typography, Grid, Button, Paper, Box, Container } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { formatTwoDigits } from 'utils';
import { useCampaignTime } from 'hooks';
import { pathOr, toLower } from 'ramda';
import { CampaignTimeTypeEnum } from 'helpers';

const useStyles = createUseStyles(
  {
    bannerItem: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      height: 600,
      flexDirection: 'column',
      background: '#fffff',
      '@media screen and (min-width: 640px)': {
        height: 800,
      },
    },
    bannerItemWithBg: {
      background: (imgLink) => `url(${imgLink}) no-repeat center`,
      backgroundSize: 'contain !important',
      maxHeight: 800,
      '&:before': {
        '@media screen and (min-width: 640px)': {
          content: '" "',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background:
            'linear-gradient(90deg, rgba(0, 0, 0, 0.9) 29.17%, rgba(0, 0, 0, 0) 88.02%)',
        },
        content: '" "',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background:
          'linear-gradient(90deg, rgba(0, 0, 0, 0.9) 5.17%, rgba(0, 0, 0, 0) 95.02%)',
      },
    },

    bannerVideoContainer: {
      '&:before, &:after': {
        content: '" "',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 10,
      },
    },
    bannerVideoContainer1: {
      '&:before': {
        background:
          'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%)',
        zIndex: 10,
      },
      '&:after': {
        background:
          'linear-gradient(90deg, rgba(255,255,255,0) 95%, rgba(0,0,0,1) 100%)',
      },
    },
    bannerVideoContainer2: {
      '&:before': {
        background:
          'linear-gradient(180deg, rgba(0,0,0,1) 3%, rgba(255,255,255,0) 10%)',
      },
      '&:after': {
        background:
          'linear-gradient(180deg, rgba(255,255,255,0) 83%, rgba(0,0,0,1) 97%)',
      },
    },
  },
  {
    name: 'BannerItem',
  },
);

export const BannerItem: React.FC<any> = ({ campaignData }) => {
  const classes = useStyles(campaignData.cover);

  const rounds = useMemo(
    () =>
      campaignData.rounds.map((round: any) => ({
        type: round.type,
        startTime: round.start_time,
        endTime: round.end_time,
        vip_access_only: round.vip_access_only,
      })),
    [campaignData.rounds],
  );
  const { isStarted, isFinished, roundLabel, countdown, currentRound } =
    useCampaignTime(rounds);

  const isSoldOut = useMemo(() => {
    const remainQuota = pathOr(
      null,
      ['saleStatus', 'remainQuota'],
      campaignData,
    );
    if (remainQuota === null) return null;
    return remainQuota <= 0;
  }, [campaignData]);

  const fcfsRound = rounds.find((round: any) => {
    return round.type === CampaignTimeTypeEnum.FCFS_ROUND;
  });
  const vipOnlyFCFS = useMemo(
    () => pathOr([], ['vip_access_only'], fcfsRound),
    [campaignData],
  );
  const isFCFS = useMemo(() => {
    if (!isStarted) return false;
    if (isFinished) return false;
    if (!currentRound) return false;

    return currentRound.type === CampaignTimeTypeEnum.FCFS_ROUND;
  }, [isStarted, isFinished, currentRound]);

  const isFreeForAll = useMemo(
    () => isFCFS && !vipOnlyFCFS,
    [vipOnlyFCFS, isFCFS],
  );

  const totalRaiseIsTBA = campaignData.total_raise + '' === '1000000000';
  const allowToViewCampaignDetail =
    !totalRaiseIsTBA && !campaignData.disable_to_view_details;
  const campaignUrl = `/${toLower(campaignData.type)}/${
    campaignData.slug || campaignData.id
  }`;

  const video_cover_url = useMemo(() => {
    if (!campaignData.video_cover) return;

    const matches = campaignData.video_cover.match(
      /embed\/(?<videoId>[a-zA-Z0-9\-]+)\"/,
    );
    if (!matches) return;
    const { videoId } = matches.groups;

    return `https://www.youtube.com/embed/${videoId}?playlist=${videoId}&autoplay=1&mute=1&loop=1&rel=0&controls=0`;
  }, [campaignData.video_cover]);

  return (
    <div
      className={[
        'relative w-full text-center items-center justify-center',
        classes.bannerItem,
        video_cover_url ? '' : classes.bannerItemWithBg,
      ].join(' ')}
    >
      {video_cover_url && (
        <>
          <div className="absolute w-full h-full">
            <Container className="relative h-full">
              <div
                className={[
                  'absolute h-full w-full',
                  classes.bannerVideoContainer,
                  classes.bannerVideoContainer1,
                ].join(' ')}
              >
                <div
                  className={[
                    'absolute h-full w-full',
                    classes.bannerVideoContainer,
                    classes.bannerVideoContainer2,
                  ].join(' ')}
                >
                  <iframe
                    className="w-full h-full"
                    src={video_cover_url}
                  ></iframe>
                </div>
              </div>
            </Container>
          </div>
        </>
      )}
      <Container className="flex justify-center items-center px-6 z-20">
        <Grid
          container
          className="flex justify-center items-center"
          style={{
            maxWidth: '1500px',
          }}
        >
          <Grid item xs={12} md={12} lg={7} xl={5} className="flex flex-col">
            <div className="flex flex-col items-start xl:items-start rounded-lg sm:pt-0 py-0 sm:py-20 z-10">
              {video_cover_url ? (
                <div className="h-[200px]" />
              ) : (
                <img
                  src={campaignData.avatar}
                  className="ml-7 rounded-full mt-[40px] sm:mt-[112px]"
                  style={{
                    maxWidth: '146px',
                    maxHeight: 'auto',
                  }}
                />
              )}
              {isFinished ? (
                <Paper
                  style={{ backgroundColor: 'transparent' }}
                  className="p-9 mt-4 flex justify-center items-center shadow-none"
                >
                  <div>
                    <Typography
                      variant="h1"
                      align="left"
                      className="text-4xl sm:text-5xl mb-2 sm:mb-8 font-bold text-white"
                      style={{ lineHeight: '80px', fontSize: '64px' }}
                    >
                      <a
                        href={campaignUrl}
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'clip',
                          textOverflow: 'ellipsis',
                          display: 'block',
                          width: '100%',
                          height: '70px',
                        }}
                      >
                        {campaignData.name}
                      </a>
                    </Typography>
                    <Button
                      className="flex relative w-[126px] h-[52px] rounded-lg"
                      href={campaignUrl}
                    >
                      Participate
                    </Button>
                  </div>
                </Paper>
              ) : (
                <Paper
                  style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
                  className=" w-full p-8"
                >
                  <div className="flex flex-col gap-4">
                    <div>
                      <Typography
                        variant="h1"
                        align="left"
                        className="mb-2 sm:mb-8 font-bold text-white"
                      >
                        <a
                          className="text-[32px] sm:text-[64px]"
                          href={campaignUrl}
                          style={{
                            whiteSpace: 'nowrap',
                            display: 'block',
                            width: '100%',
                            height: '70px',
                          }}
                        >
                          {campaignData.name}
                        </a>
                      </Typography>

                      <div
                        className="text-lg sm:mt-6 font-bold bg-clip-text text-transparent uppercase text-left"
                        style={{
                          background:
                            '-webkit-linear-gradient(90deg, rgba(255,130,130,1) 0%, rgba(255,196,79,1) 70%, rgba(255,81,0,1) 100%)',
                          // '-webkit-background-clip': 'text',
                          // '-webkit-text-fill-color': 'transparent',
                        }}
                      >
                        {totalRaiseIsTBA
                          ? 'COMING SOON'
                          : isFreeForAll
                          ? 'FCFS ROUND ENDS IN'
                          : roundLabel}
                      </div>
                    </div>
                    {!totalRaiseIsTBA && (
                      <div className="text-base text-light-gray font-bold ">
                        <div>
                          <div className="hidden sm:flex">
                            <div>
                              <div className="flex justify-center items-center">
                                <Box
                                  className="flex font-medium text-white  "
                                  style={{
                                    fontSize: '40px',
                                    position: 'relative',

                                    background:
                                      'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.05) 100%)',
                                    width: '78px',
                                    height: '78px',
                                    borderRadius: '16px',
                                  }}
                                >
                                  <span className="mx-auto mt-6">
                                    {formatTwoDigits(countdown.days)}
                                  </span>
                                </Box>
                              </div>
                              <div
                                className="text-center"
                                style={{
                                  margin: 'auto',
                                  fontSize: '12px',
                                  lineHeight: '16px',
                                  color: 'rgba(255, 255, 255, 0.5)',
                                }}
                              >
                                DAYS
                              </div>
                            </div>
                            <div className="font-bold text-4xl flex mt-3 mx-4">
                              :
                            </div>
                            <div>
                              <div className="flex justify-center items-center">
                                <Box
                                  className="flex font-medium text-white  "
                                  style={{
                                    fontSize: '40px',
                                    position: 'relative',

                                    background:
                                      'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.05) 100%)',
                                    width: '78px',
                                    height: '78px',
                                    borderRadius: '16px',
                                  }}
                                >
                                  <span className="mx-auto mt-6">
                                    {formatTwoDigits(countdown.hours)}
                                  </span>
                                </Box>
                              </div>
                              <div
                                className="text-center"
                                style={{
                                  margin: 'auto',
                                  fontSize: '12px',
                                  lineHeight: '16px',
                                  color: 'rgba(255, 255, 255, 0.5)',
                                }}
                              >
                                HOURS
                              </div>
                            </div>
                            <div className="font-bold text-4xl flex mt-3 mx-4">
                              :
                            </div>
                            <div>
                              <div className="flex justify-center items-center">
                                <Box
                                  className="flex font-medium text-white  "
                                  style={{
                                    fontSize: '40px',
                                    position: 'relative',

                                    background:
                                      'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.05) 100%)',
                                    width: '78px',
                                    height: '78px',
                                    borderRadius: '16px',
                                  }}
                                >
                                  <span className="mx-auto mt-6">
                                    {formatTwoDigits(countdown.minutes)}
                                  </span>
                                </Box>
                              </div>
                              <div
                                className="text-center"
                                style={{
                                  margin: 'auto',
                                  fontSize: '12px',
                                  lineHeight: '16px',
                                  color: 'rgba(255, 255, 255, 0.5)',
                                }}
                              >
                                MINUTES
                              </div>
                            </div>
                            <div className="font-bold text-4xl flex mt-3 mx-4">
                              :
                            </div>
                            <div>
                              <div className="flex justify-center items-center">
                                <Box
                                  className="flex font-medium text-white  "
                                  style={{
                                    fontSize: '40px',
                                    position: 'relative',

                                    background:
                                      'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.05) 100%)',
                                    width: '78px',
                                    height: '78px',
                                    borderRadius: '16px',
                                  }}
                                >
                                  <span className="mx-auto mt-6">
                                    {formatTwoDigits(countdown.seconds)}
                                  </span>
                                </Box>
                              </div>
                              <div
                                className="text-center"
                                style={{
                                  margin: 'auto',
                                  fontSize: '12px',
                                  lineHeight: '16px',
                                  color: 'rgba(255, 255, 255, 0.5)',
                                }}
                              >
                                SECONDS
                              </div>
                            </div>
                          </div>
                          <div className="flex sm:hidden">
                            <div>
                              <div
                                className="flex justify-center items-center"
                                style={{
                                  border: '1px solid rgba(255, 255, 255, 0.4)',
                                  borderRadius: '10px',
                                }}
                              >
                                <div
                                  className="flex justify-center items-center"
                                  style={{
                                    width: '50px',
                                    height: '50px',
                                    background:
                                      'linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))',
                                    borderRadius: '10px',
                                  }}
                                >
                                  <span
                                    className="font-semibold text-white"
                                    style={{ fontSize: '32px' }}
                                  >
                                    {formatTwoDigits(countdown.days)}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="text-center"
                                style={{
                                  margin: 'auto',
                                  fontSize: '12px',
                                  lineHeight: '44px',
                                  color: 'rgba(255, 255, 255, 0.5)',
                                }}
                              >
                                Days
                              </div>
                            </div>
                            <div className="font-bold text-4xl flex mt-2 mx-4">
                              :
                            </div>
                            <div>
                              <div
                                className="flex justify-center items-center"
                                style={{
                                  border: '1px solid rgba(255, 255, 255, 0.4)',
                                  borderRadius: '10px',
                                }}
                              >
                                <div
                                  className="flex justify-center items-center"
                                  style={{
                                    width: '50px',
                                    height: '50px',
                                    background:
                                      'linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))',
                                    borderRadius: '10px',
                                  }}
                                >
                                  <span
                                    className="font-semibold text-white"
                                    style={{ fontSize: '32px' }}
                                  >
                                    {formatTwoDigits(countdown.hours)}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="text-center"
                                style={{
                                  margin: 'auto',
                                  fontSize: '12px',
                                  lineHeight: '44px',
                                  color: 'rgba(255, 255, 255, 0.5)',
                                }}
                              >
                                Hours
                              </div>
                            </div>
                            <div className="font-bold text-xl flex mt-2 mx-4">
                              :
                            </div>
                            <div>
                              <div
                                className="flex justify-center items-center"
                                style={{
                                  border: '1px solid rgba(255, 255, 255, 0.4)',
                                  borderRadius: '10px',
                                }}
                              >
                                <div
                                  className="flex justify-center items-center"
                                  style={{
                                    width: '50px',
                                    height: '50px',
                                    background:
                                      'linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))',
                                    borderRadius: '10px',
                                  }}
                                >
                                  <span
                                    className="font-semibold text-white"
                                    style={{ fontSize: '32px' }}
                                  >
                                    {formatTwoDigits(countdown.minutes)}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="text-center"
                                style={{
                                  margin: 'auto',
                                  fontSize: '12px',
                                  lineHeight: '44px',
                                  color: 'rgba(255, 255, 255, 0.5)',
                                }}
                              >
                                Minutes
                              </div>
                            </div>
                            <div className="font-bold text-xl flex mt-2 mx-4">
                              :
                            </div>
                            <div>
                              <div
                                className="flex justify-center items-center"
                                style={{
                                  border: '1px solid rgba(255, 255, 255, 0.4)',
                                  borderRadius: '10px',
                                }}
                              >
                                <div
                                  className="flex justify-center items-center"
                                  style={{
                                    width: '50px',
                                    height: '50px',
                                    background:
                                      'linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))',
                                    borderRadius: '10px',
                                  }}
                                >
                                  <span
                                    className="font-semibold text-white"
                                    style={{ fontSize: '32px' }}
                                  >
                                    {formatTwoDigits(countdown.seconds)}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="text-center"
                                style={{
                                  margin: 'auto',
                                  fontSize: '12px',
                                  lineHeight: '44px',
                                  color: 'rgba(255, 255, 255, 0.5)',
                                }}
                              >
                                Seconds
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {allowToViewCampaignDetail && (
                    <Button
                      className="flex relative w-[126px] h-[52px] rounded-lg mt-5 sm:mt-[60px]"
                      href={campaignUrl}
                    >
                      Participate
                    </Button>
                  )}
                </Paper>
              )}
            </div>
          </Grid>
          <Grid
            item
            lg={5}
            xl={7}
            className="xl:flex justify-center items-center"
          >
            {isSoldOut === true ? (
              <div
                className="p-3 rounded-3xl"
                style={{
                  background: 'rgba(0, 0, 0, 0.7)',
                }}
              >
                <img src={'/assets/imgs/soldout.png'} />
              </div> ? (
                isFinished
              ) : (
                <div
                  className="p-3 rounded-3xl"
                  style={{
                    background: 'rgba(0, 0, 0, 0.7)',
                  }}
                >
                  <img src={'/assets/imgs/soldout.png'} />
                </div>
              )
            ) : null}
          </Grid>
          <Grid
            item
            lg={5}
            xl={7}
            className="xl:flex justify-center items-center"
          >
            {/* <LazyLoadImage
            src={"/assets/imgs/soldout.png"}
            style={{
              borderRadius: "24px",
            }}
          /> */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
