import Lottie from 'react-lottie';
import animationData from './helpers/config.json';
import './ProgressBar.scss';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const ProgressBar: React.FC<{
  progressPercent: string;
}> = ({ progressPercent }) => {
  return (
    <div className="flex w-full justify-start items-center rounded-[18px] h-6 bg-[#191B1E] campaign-progress-bar">
      <Lottie
        options={defaultOptions}
        height={20}
        style={{
          height: '20px',
          borderRadius: '18px',
          width: `${progressPercent}%`,
          margin: 'unset',
        }}
      />
    </div>
  );
};
