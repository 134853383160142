import { Typography } from "@mui/material";

import styles from "./Steps.module.scss";

const Step = ({ no, imageUrl, title, url, className }) => {
  return (
    <a
      href={url}
      target="_blank"
      className={[
        styles.stepContainer,
        "flex flex-row w-full justify-center mt-6 md:flex-col md:w-[unset]md:mt-0 z-10",
        className,
      ].join(" ")}
    >
      <div
        style={{
          background: `url(assets/imgs/projects/step.png)`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
        className={[
          styles.step,
          "flex justify-center items-center mt-0 md:mt-4 h-32 w-32",
        ].join(" ")}
      >
        <img
          className={styles.stepIcon}
          src={imageUrl}
          alt="icon 1"
          width={38}
          height={38}
        />
      </div>
      <span
        className={[
          styles.stepTitle,
          "w-1/3 ml-6 text-left md:w-full md:ml-0 md:mt-6 md:text-center",
        ].join(" ")}
      >
        {title}
      </span>
    </a>
  );
};

export const Steps = ({ className }) => {
  return (
    <div
      className={[
        "flex flex-col justify-center items-center h-96",
        styles.steps,
        className,
      ].join(" ")}
      style={{
        background: `url(assets/imgs/projects/step-background.png)`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Typography variant="h3" className="text-[36px]">
        Steps to join IDO & INO
      </Typography>
      <div className="flex w-10/12 items-center">
        <Step
          no="1"
          imageUrl={"assets/imgs/projects/stake.svg"}
          title="STAKE"
          className="items-center"
        />
        <div
          style={{
            height: "1px",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            width: "100%",
          }}
        />
        {/* <Step
          no="2"
          imageUrl={"assets/imgs/projects/kyc.svg"}
          title="KYC"
          className="items-center"
        />
        <div
          style={{
            height: "1px",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            width: "100%",
          }}
        /> */}
        <Step
          no="3"
          imageUrl={"assets/imgs/projects/register.svg"}
          title="REGISTER"
          className="items-center"
        />
        <div
          style={{
            height: "1px",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            width: "100%",
          }}
        />
        <Step
          no="4"
          imageUrl={"assets/imgs/projects/buy.svg"}
          title="BUY"
          className="items-center"
        />
        <div
          style={{
            height: "1px",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            width: "100%",
          }}
        />
        <Step
          no="5"
          imageUrl={"assets/imgs/projects/claim.svg"}
          title="CLAIM"
          className="items-center"
        />
      </div>
    </div>
  );
};
