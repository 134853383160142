import { renderProjectRoutes } from './helpers';

import { routesConfig as campaignDetailsRoutesConfig } from 'views/CampaignDetails';

export const renderProjectsRoute = () => {
  return (
    <>
      {renderProjectRoutes(campaignDetailsRoutesConfig)}
    </>
  );
};
