import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import { useInterval } from 'hooks';
import { isEmpty } from 'ramda';

import { Steps } from 'components/Steps/Steps';
import {
  Banner,
  ActiveProjects,
  CompletedSales,
  Upcoming,
  Footer,
} from './Components';
import { getCampaignApi } from 'swagger';
import { CampaignStatusEnum, CampaignTimeTypeEnum } from 'helpers';
import { toUtc } from '../../utils/common/date/toUtc';
import { toJson } from 'utils';
import { PoweredByCryptorank } from 'components/PoweredByCryptorank';

const getStartTime = (
  campaignTimes: any,
  campaignTimeTypes: CampaignTimeTypeEnum[],
) => {
  for (const campaignTimeType of campaignTimeTypes) {
    const campaignTime = campaignTimes.find(
      (campaignTime: any) => campaignTime.type === campaignTimeType,
    );
    if (campaignTime) return campaignTime.start_time;
  }
};

const Home = () => {
  const [campaigns, setCampaigns] = React.useState<any[]>([]);
  const [keyRender, setKeyRender] = React.useState({});
  const [upcomingProjectsData, setUpcomingProjectsData] = React.useState<any[]>(
    [],
  );
  const [activeProjectsData, setActiveProjectsData] = React.useState<any[]>([]);
  const [completedSalesData, setCompletedSalesData] = React.useState<any[]>([]);

  useEffect(() => {
    const fetchCampaigns = () => {
      getCampaignApi()
        .apiCampaignGet({
          limit: 45,
          page: 1,
          status: ['Live', 'Finished'],
          includeRemainQuota: true,
        })
        .then(toJson)
        .then((res) => {
          for (const campaign of res.items) {
            campaign.campaignTimes = res.campaignTimes.filter(
              (campaignTime: any) => campaignTime.campaign_id === campaign.id,
            );
          }

          setCampaigns(res.items);
        });
    };

    fetchCampaigns();
    const intervalHandle = setInterval(() => fetchCampaigns(), 2 * 60 * 1000);
    return () => clearInterval(intervalHandle);
  }, []);

  useEffect(() => {
    if (isEmpty(campaigns)) return;

    const newUpcomingProjectsData: any[] = [];
    const newActiveProjectsData: any[] = [];
    const newCompletedSalesData: any[] = [];

    for (const campaign of campaigns) {
      if (campaign.status === CampaignStatusEnum.Finished) {
        newCompletedSalesData.push(campaign);
      } else {
        const startTime = getStartTime(campaign.campaignTimes, [
          CampaignTimeTypeEnum.CAMPAIGN_TIME,
        ]);
        if (toUtc(startTime) < toUtc(new Date())) {
          newActiveProjectsData.push(campaign);
        } else {
          newUpcomingProjectsData.push(campaign);
        }
      }
    }

    setUpcomingProjectsData(newUpcomingProjectsData.reverse());
    setActiveProjectsData(newActiveProjectsData);
    setCompletedSalesData(newCompletedSalesData);
  }, [campaigns, keyRender]);

  useInterval(
    () => {
      if (isEmpty(upcomingProjectsData) && isEmpty(activeProjectsData)) return;
      setKeyRender({});
    },
    3000,
    [upcomingProjectsData, activeProjectsData],
  );

  return (
    <>
      <div className="text-white">
        <Banner />
        <div style={{ backgroundColor: '#191B1E' }}>
          <Container className="sm:py-12 text-center">
            <Steps className="w-12/12 hidden sm:flex" />
          </Container>

          {!!activeProjectsData.length && (
            <Container className="py-12">
              <ActiveProjects projects={activeProjectsData} />
            </Container>
          )}

          {!!upcomingProjectsData.length && (
            <Container className="py-12">
              <Upcoming projects={upcomingProjectsData} />
            </Container>
          )}

          <Container className="py-12 mb-20">
            <CompletedSales projects={completedSalesData} />
          </Container>

          <Footer url="https://docs.google.com/forms/d/1K_GPDM6RmYbz60GWyEy9IKXUyEGBrRqlTnmqU8Gd1cw/viewform?edit_requested=true#settings" />
          <div className="h-20" />
        </div>
      </div>
      <PoweredByCryptorank />
    </>
  );
};

export default Home;
