import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { PrivateLayout } from "layouts";
import { AppController, AppTheme } from "containers";
import { queryClient } from "services/client";

import "./App.scss";

import { Home } from "views/Home";
import { Loading } from "components";
import { renderProjectsRoute } from "routes";
import { lazyLoadThenCreateComponent } from "utils";

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppTheme>
        <Router>
          <AppController>
            <Suspense fallback={<Loading text="Loading, please wait..." />}>
              <Routes>
                <Route path="/" element={<PrivateLayout />}>
                  <Route index element={<Home />} />
                  <Route
                    path="user"
                    element={lazyLoadThenCreateComponent(
                      () => import("../views/UserPage"),
                      "UserPage"
                    )}
                  >
                    <Route
                      path={"profile"}
                      element={lazyLoadThenCreateComponent(
                        () => import("../views/UserProfile"),
                        "UserProfile"
                      )}
                    />
                    <Route
                      path={"order-history"}
                      element={lazyLoadThenCreateComponent(() =>
                        import("../views/OrderHistory")
                      )}
                    />
                  </Route>
                  <Route
                    path={"airdrop"}
                    element={lazyLoadThenCreateComponent(
                      () => import("../views/Airdrop"),
                      "Airdrop"
                    )}
                  />
                  {renderProjectsRoute()}
                </Route>
              </Routes>
            </Suspense>
          </AppController>
        </Router>
      </AppTheme>
    </QueryClientProvider>
  );
};
