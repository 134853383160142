import { CampaignTimeTypeEnum } from 'helpers';
import { useState, useEffect, useMemo } from 'react';
import { toUtc } from 'utils/common/date';
import { useInterval } from './useInterval';

export type ICountdown = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number | string;
};

const getCampaignTime = (rounds: any[]) =>
  rounds.find((r) => r.type === CampaignTimeTypeEnum.CAMPAIGN_TIME);

const getFirstMatchedRound = (rounds: any[], types: CampaignTimeTypeEnum[]) => {
  for (const type of types) {
    const round = rounds.find((r) => r.type === type);
    if (!!round) {
      return round;
    }
  }
};

const hasAnotherRound = (currentRound: any, otherRounds: any[]) => {
  otherRounds = otherRounds || [];
  if (currentRound.type === CampaignTimeTypeEnum.REGISTRATION) {
    return otherRounds.some((r: any) => [CampaignTimeTypeEnum.VIP_ROUND, CampaignTimeTypeEnum.DOUBLE_QUOTA_ROUND, CampaignTimeTypeEnum.TRIPLE_QUOTA_ROUND, CampaignTimeTypeEnum.FCFS_ROUND].includes(r.type));
  } else if (currentRound.type === CampaignTimeTypeEnum.VIP_ROUND) {
    return otherRounds.some((r: any) => [CampaignTimeTypeEnum.DOUBLE_QUOTA_ROUND, CampaignTimeTypeEnum.TRIPLE_QUOTA_ROUND, CampaignTimeTypeEnum.FCFS_ROUND].includes(r.type));
  } else if (currentRound.type === CampaignTimeTypeEnum.DOUBLE_QUOTA_ROUND || currentRound.type === CampaignTimeTypeEnum.TRIPLE_QUOTA_ROUND) {
    return otherRounds.some((r: any) => [CampaignTimeTypeEnum.FCFS_ROUND].includes(r.type));
  } if (currentRound.type === CampaignTimeTypeEnum.FCFS_ROUND) {
    return false;
  }
}

const getTimeDiff = (time1: Date, time2: Date): ICountdown => {
  const diff = time1.getTime() - time2.getTime();
  return {
    days: Math.floor(diff / (1000 * 60 * 60 * 24)),
    hours: Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((diff % (1000 * 60)) / 1000),
  };
};

const getCurrentRound = (isStarted: boolean, rounds: any[]) => {
  const currentTime = toUtc(new Date());
  if (!isStarted) {
    // const registrationRound = rounds.find(
    //   (round: any) => round.type === CampaignTimeTypeEnum.REGISTRATION,
    // );
    // if (registrationRound) {
    //   const registrationEndTime = toUtc(registrationRound.endTime);
    //   if (currentTime < registrationEndTime) {
    //     return registrationRound;
    //   }
    // }


    const round = rounds.find(
      (round: any) => round.type === CampaignTimeTypeEnum.CAMPAIGN_TIME,
    );
    if (round) {
      const startTime = toUtc(round.startTime);
      if (currentTime >= startTime) {
        return round;
      }
    }
  } else {
    const filteredRounds = rounds.filter(
      (round: any) =>
        round.type !== CampaignTimeTypeEnum.CAMPAIGN_TIME &&
        round.type !== CampaignTimeTypeEnum.REGISTRATION,
    );
    
    for (const round of filteredRounds) {
      let isOk = true;
      if (round.startTime) {
        const startTime = toUtc(round.startTime);
        if (currentTime >= startTime) {
          isOk = true;
        } else {
          isOk = false;
        }
      }
      if (isOk && hasAnotherRound(round, filteredRounds) && round.endTime) {
        const endTime = toUtc(round.endTime);
        if (currentTime <= endTime) {
          isOk = true;
        } else {
          isOk = false;
        }
      }
      if (isOk) {
        return round;
      }
    }
  }
};

export const useCampaignTime = (rounds: any[]) => {
  const [isStarted, setIsStarted] = useState<boolean | null>(null);
  const [isFinished, setIsFinished] = useState(false);
  const [currentRound, setCurrentRound] = useState<any>(null);
  const [roundLabel, setRoundLabel] = useState('');
  const [countdown, setCountdown] = useState<ICountdown>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: '',
  });
  const [countdownAsSeconds, setCountdownAsSeconds] = useState(-1);

  const allRounds = useMemo(() => {
    if (!rounds || rounds.length === 0) return [];

    const campaignTime = getCampaignTime(rounds);

    if (!campaignTime) {
      const vipRound = getFirstMatchedRound(rounds, [
        CampaignTimeTypeEnum.VIP_ROUND,
      ]);
      const endRound = getFirstMatchedRound(rounds, [
        CampaignTimeTypeEnum.FCFS_ROUND,
        CampaignTimeTypeEnum.TRIPLE_QUOTA_ROUND,
        CampaignTimeTypeEnum.DOUBLE_QUOTA_ROUND,
        CampaignTimeTypeEnum.VIP_ROUND,
      ]);
      return [
        ...rounds,
        {
          type: CampaignTimeTypeEnum.CAMPAIGN_TIME,
          startTime: vipRound.startTime,
          endTime: endRound.endTime,
        },
      ];
    }
    return rounds;
  }, [rounds]);

  useEffect(() => {
    if (!allRounds || allRounds.length === 0) return;

    if (!isFinished) {      if (!!currentRound) {
        const roundType = currentRound.type;
        switch (roundType) {
          case CampaignTimeTypeEnum.VIP_ROUND:
            setRoundLabel('VIP Round ends in');
            break;
          case CampaignTimeTypeEnum.TRIPLE_QUOTA_ROUND:
            setRoundLabel('Triple Quota Round ends in');
            break;
          case CampaignTimeTypeEnum.DOUBLE_QUOTA_ROUND:
            setRoundLabel('Double Quota Round ends in');
            break;
          case CampaignTimeTypeEnum.FCFS_ROUND:
            setRoundLabel('FCFS Round (VIP only) ends in');
            break;
        }
      } else {
        setRoundLabel('Sale starts in');
      }
    } else {
      setRoundLabel('Sale has ended');
    }
  }, [currentRound, isFinished, allRounds]);

  useInterval(
    () => {
      if (!allRounds || allRounds.length === 0 || isFinished) return;
      const currentTime = toUtc(new Date());

      const campaignTime = getCampaignTime(allRounds);

      if (!campaignTime) return;

      const campaignStartTime = toUtc(campaignTime.startTime);
      const isStart = currentTime >= campaignStartTime;
      setIsStarted(isStart);

      const campaignEndTime = toUtc(campaignTime.endTime);
      const isEnd = currentTime >= campaignEndTime;
      setIsFinished(isEnd);

      const round = getCurrentRound(isStart, rounds);

      setCurrentRound(round);

      if (!round) {
        if (!isStart) {
          const campaignTime = getCampaignTime(allRounds);

          if (!campaignTime) return;
          setCountdown(getTimeDiff(toUtc(campaignTime.startTime), currentTime));
        }
      } else {
        let endTime = getCampaignTime(allRounds).endTime;
        if (hasAnotherRound(round, allRounds) && round.endTime){
          endTime = round.endTime;
        }

        setCountdown(getTimeDiff(toUtc(endTime), currentTime));
      }
    },
    1000,
    [allRounds, isFinished],
  );

  useEffect(() => {
    if (isFinished) return;

    if (!countdown || countdown.seconds === '') {
      setCountdownAsSeconds(-1);
    } else {
      setCountdownAsSeconds(
        Number(countdown.seconds) +
        countdown.minutes * 60 +
        countdown.hours * 60 * 60 +
        countdown.days * 60 * 60 * 24,
      );
    }
  }, [countdown, isFinished]);

  const result = {
    isStarted,
    isFinished,
    currentRound,
    roundLabel,
    countdown,
    countdownAsSeconds,
  };

  return result;
};
