import React, { memo, useState, useEffect } from 'react';
import { BannerItem } from './Items/BannerItem';
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import Slider from 'react-slick';
import { useWindowDimensions } from 'hooks/useWindowDimensions';

import { ApiCampaignGetArgs, getCampaignApi } from '../../../../swagger';
import { toJson } from 'utils';
import { QuerySortEnum } from 'helpers';

const styles = {
  slickSlideController: {
    color: '#fff',
    fontSize: '36px',
  },
};
const limit = 5;
const queryCampaigns = async (queryParams: ApiCampaignGetArgs) => {
  try {
    const { items, campaignTimes } = await getCampaignApi()
      .apiCampaignGet(queryParams)
      .then(toJson);

    for (const item of items) {
      item.rounds = campaignTimes.filter(
        (campaignTime: any) => campaignTime.campaign_id === item.id,
      );
    }

    return items;
  } catch (error) {
    console.log(error);
  }
};

export const Banner = memo(() => {
  const slider = React.useRef<any>(null);
  const { width } = useWindowDimensions();
  const goNext = () => slider?.current?.slickNext();
  const goPrev = () => slider?.current?.slickPrev();
  const [allCampaign, setAllCampaign] = useState([]);

  useEffect(() => {
    const getBanners = async () => {
      let items = await queryCampaigns({
        limit,
        on_banner: true,
        status: ['Live'],
        includeRemainQuota: true,
        orderBy: QuerySortEnum.LATEST
      });

      items = items.reverse()

      if (items.length < 1) {
        const finnishItems = await queryCampaigns({
          limit: 3 - items.length,
          status: ['Finished'],
          includeRemainQuota: true,
          orderBy: QuerySortEnum.LATEST
        });

        items = [...items, ...finnishItems.reverse()]
      }

      setAllCampaign(items || []);
    };

    getBanners();
    const intervalHandle = setInterval(() => getBanners(), 60 * 1000);
    return () => clearInterval(intervalHandle);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 7500,
    dotsClass: '',
    arrows: false,
    appendDots: (dots: any) => (
      <div>
        <div className="absolute w-full justify-center md:w-7 flex md:flex-col items-center gap-4 md:top-72 md:right-24">
          {width >= 768 ? (
            <KeyboardArrowUp
              className="cursor-pointer"
              sx={styles.slickSlideController}
              onClick={goPrev}
            />
          ) : (
            <KeyboardArrowLeft
              className="cursor-pointer"
              sx={styles.slickSlideController}
              onClick={goPrev}
            />
          )}
          <ul className="flex md:flex-col gap-4 cursor-pointer">{dots}</ul>
          {width >= 768 ? (
            <KeyboardArrowDown
              className="cursor-pointer"
              sx={styles.slickSlideController}
              onClick={goNext}
            />
          ) : (
            <KeyboardArrowRight
              className="cursor-pointer"
              sx={styles.slickSlideController}
              onClick={goNext}
            />
          )}
        </div>
      </div>
    ),
    customPaging: (i: unknown) => (
      <div
        className="h-4 w-4 rounded-full fill-dot"
        style={{ border: '1px solid #fff' }}
      />
    ),
  };

  return allCampaign.length > 0 ? (
    <Slider ref={slider} {...settings}>
      {allCampaign.map((campaign, index) => (
        <div key={index}>{<BannerItem campaignData={campaign} />}</div>
      ))}
    </Slider>
  ) : null;
});
