import { Container } from '@mui/material';
import { useLayoutEffect } from 'react';

export const PoweredByCryptorank = () => {
  useLayoutEffect(() => {
    setTimeout(() => {
      const script = document.createElement('script');

      script.src = 'https://cryptorank.io/widget/marquee.js';
      script.async = true;

      document.body.appendChild(script);
    }, 1000);
  }, []);

  return (
    <Container className="py-10 text-center">
      <div
        id="cr-widget-marquee"
        data-coins="bitcoin,ethereum,bnb"
        data-theme="dark"
        data-show-symbol="true"
        data-show-icon="true"
        data-show-period-change="true"
        data-period-change="24H"
        data-api-url="https://api.cryptorank.io/v0"
      >
        <a href="https://cryptorank.io">Powered by Cryptorank</a>
      </div>
      <a
        className="flex flex-row justify-center align-baseline gap-2 mt-3"
        href="https://cryptorank.io/"
        target="_blank"
      >
        <span className="text-white">POWERED BY</span>
        <img
          className="h-[24px]"
          src="/assets/imgs/others/cryptorank.png"
          alt="cryptorank logo"
        />
      </a>
    </Container>
  );
};
