import { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import { formatNumber, isNumeric, toJson, toUtcDayjs } from 'utils/common';
import {
  Button,
  Typography,
  LinearProgress,
  linearProgressClasses,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  LazyLoadImage,
  trackWindowScroll,
} from 'react-lazy-load-image-component';
import {
  CampaignStatusEnum,
  CampaignTimeTypeEnum,
  CampaignTypeEnum,
} from 'helpers';
import { propOr, toLower } from 'ramda';
import { getItemApi } from 'swagger';

export const TotalRaiseProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 18,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    background: '#222325',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 18,
    background:
      'linear-gradient(270deg, rgba(255, 239, 94, 1) 0%, rgba(247, 147, 111, 1) 100%)',
  },
}));

const useStyles = createUseStyles(
  {
    projectCard: {
      minHeight: '400px',
      border: '1px solid transparent',
      overflow: 'hidden',
    },
    link: {
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#EECE7C',
      },
    },
    projectCardBanner: {
      maxHeight: '517px',
      margin: '-1.5rem',
      position: 'relative',
      '&:after': {
        position: 'absolute',
        content: '" "',
        top: '50%',
        left: 0,
        right: 0,
        bottom: 0,
        background:
          'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
      },
    },
    projectName: {
      fontSize: '20px',
      lineHeight: '30px',
      fontFeatureSettings: "'tnum' on, 'lnum' on",
    },
    currency: {
      fontSize: '20px',
      lineHeight: '30px',
      fontFeatureSettings: "'tnum' on, 'lnum' on",
    },
    tokenTag: {
      fontSize: '16px',
      lineHeight: '24px',
      fontFeatureSettings: "'tnum' on, 'lnum' on",
      color: '#a9a9b0',
      '&:not(:first-child)::before': {
        content: '" / "',
        display: 'inline-block',
        margin: '0 .5rem',
      },
    },
    projectCardDescription: {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      fontFeatureSettings: "'tnum' on, 'lnum' on",
      color: '#87878D',
    },
    projectRaise: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '34px',
      textAlign: 'right',
      fontFeatureSettings: "'tnum' on, 'lnum' on",
      color: '#EECE7C',
    },
    btnLearnMore: {
      width: '100%',
      height: '36px',
    },
    saleStatus: {
      position: 'absolute',
      zIndex: 1,
      top: '16px',
      right: 0,
      fontSeight: 700,
      fontSize: '14px',
      lineHeight: '24px',

      height: '32px',
      background: '#00B074',
      borderRadius: '40px',
      padding: '4px 12px',
    },
  },
  {
    name: 'ProjectCard',
  },
);

export const NewProjectCard = trackWindowScroll(
  ({ className, project, displayStatus }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const campaignUrl = `/${toLower(project.type)}/${
      project.slug || project.id
    }`;

    const [campaignItemQuantity, setCampaignItemQuantity] = useState(-1);
    const [INOItemsDetail, setINOItemsDetail] = useState();
    useEffect(() => {
      if (!project) return;
      if (project.type === CampaignTypeEnum.IDO) return;

      getItemApi()
        .apiItemCpIdGet({
          cpId: project.id,
        })
        .then(toJson)
        .then((data) => {
          const totalItems = data.reduce(
            (total, item) => total + item.amount,
            0,
          );
          setCampaignItemQuantity(totalItems);
        })
        .catch((err) => {
          console.log('error', err);
        });
    }, [project]);

    //get INO items
    useEffect(() => {
      if (!project) return;
      if (project.type !== CampaignTypeEnum.IDO)
        //get items
        getItemApi()
          .apiItemCpIdSaleStatusGet({
            cpId: project.id,
          })
          .then(setINOItemsDetail)
          .catch((err) => {
            console.log('error', err);
          });
    }, [project]);

    const leftAmount = INOItemsDetail?.reduce((total, object) => {
      return total + object.amount_left;
    }, 0);
    const total = INOItemsDetail?.reduce((total, object) => {
      return total + object.amount;
    }, 0);
    const soldAmount = total - leftAmount;
    const progressPercent = ((soldAmount / total) * 100).toFixed(1);

    const totalRaiseIsTBA = project.total_raise + '' === '1000000000';
    const allowToViewCampaignDetail =
      !totalRaiseIsTBA && !project.disable_to_view_details;
    const onClick = useCallback(
      (event) => {
        event.preventDefault();
        if (!allowToViewCampaignDetail) return;
        if (project.type.toLowerCase() === 'ino') {
          document.location.href = campaignUrl;
        } else {
          navigate(campaignUrl);
        }
      },
      [project, navigate],
    );

    const startTime = useMemo(() => {
      const vipRound = propOr([], 'campaignTimes', project).find((object) => {
        return object.type === CampaignTimeTypeEnum.VIP_ROUND;
      });

      if (!vipRound) return '';
      return toUtcDayjs(vipRound.start_time).format('MM/DD/YYYY HH:mm UTC');
    }, [project]);
    const currencies = propOr([], 'currencies', project).join(' / ');
    const renderContent = () => (
      <>
        {project.safeguard_enable && (
          <div className="flex flex-row absolute top-0 left-0 z-10">
            <img
              src="/assets/imgs/xl-icons/safeguardBanner.png"
              width="135"
              height="26"
              alt="safeguard icon"
            />
          </div>
        )}

        {/* {project.refunds_enable && (
          <div className="flex flex-row absolute top-4 right-6 z-10">
            <img
              src="/assets/imgs/xl-icons/refunded.png"
              width="80"
              height="20"
              alt="refund icon"
            />
          </div>
        )} */}

        <div className="relative">
          {/* {!!project.saleStatus && <span className={classes.saleStatus}>{project.saleStatus}</span>} */}
          <div
            className={classes.projectCardBanner}
            style={{ maxHeight: '517px' }}
          >
            <LazyLoadImage
              src={project.cover}
              alt={project.name}
              className="w-full object-cover"
              style={{ height: '221px' }}
            />
          </div>
          <div className="flex flex-row absolute bottom-4 left-0 items-center">
            <div className="mr-4">
              {project.disable === true ? (
                <LazyLoadImage src={project.avatar} alt={project.name} />
              ) : (
                <LazyLoadImage
                  src={project.avatar}
                  alt={project.name}
                  style={{
                    height: '58px',
                    width: '58px',
                    borderRadius: '8px',
                    border: '2px solid #FFFFFF',
                  }}
                />
              )}
            </div>
            <div className="flex flex-col">
              <Typography
                className={clsx('text-white font-bold', classes.projectName)}
              >
                <span
                  style={{
                    display: 'block',
                  }}
                >
                  {project.name}
                </span>
              </Typography>
              <span
                prefix="/"
                suffix=" / "
                className={clsx('text-gray-400 font-bold', classes.currency)}
              >
                {currencies}
              </span>
              <div>
                {(project.acceptedTokens || []).map((token, index) => (
                  <span
                    key={index}
                    className={clsx(classes.tokenTag, 'font-thin')}
                  >
                    {token}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12">
          <div className="mb-4 flex justify-between items-center">
            <div className={classes.projectCardDescription}>
              {project.status === CampaignStatusEnum.Finished
                ? 'Total raised'
                : 'Targeted raise'}
            </div>
            {!!project.total_raise ? (
              <div className={clsx(classes.projectRaise, 'flex items-center')}>
                {totalRaiseIsTBA ? (
                  <span className="font-bold">TBA</span>
                ) : (
                  <span className="font-bold">
                    {formatNumber(project.total_raise, '$') || 'TBA'}
                  </span>
                )}

                {/*
                <span className='ml-1'>
                  <img src='/assets/imgs/logo-bsc.svg' width='24' height='24' alt='logo bsc' />
                </span>
                */}
              </div>
            ) : (
              <div className={clsx(classes.projectRaise, 'font-bold')}>TBA</div>
            )}
          </div>
          {propOr([], 'meta', project).map((metaItem, index) => (
            <div key={index} className="mb-4 flex justify-between items-center">
              <div className={classes.projectCardDescription}>
                {metaItem.label}
              </div>{' '}
              {!!metaItem.value ? (
                <div className="font-bold">
                  {isNumeric(metaItem.value)
                    ? formatNumber(metaItem.value, metaItem.prefix)
                    : metaItem.value}
                </div>
              ) : (
                <div className="font-bold">TBA</div>
              )}
            </div>
          ))}
          <div className="mb-4 flex justify-between items-center">
            {project.type !== CampaignTypeEnum.INO ? (
              <div className={classes.projectCardDescription}>Price</div>
            ) : (
              <div className={classes.projectCardDescription}>Quantity</div>
            )}

            {totalRaiseIsTBA ? (
              <div className="font-bold ml-2">TBA</div>
            ) : project.type !== CampaignTypeEnum.INO ? (
              <div className="font-bold ml-2">{'$' + project.token_price}</div>
            ) : campaignItemQuantity !== -1 ? (
              <div className="font-bold ml-2">{campaignItemQuantity} items</div>
            ) : (
              <div className="font-bold ml-2">-</div>
            )}
          </div>
          {startTime && (
            <div className="mb-4 flex justify-between items-center">
              <div className={classes.projectCardDescription}>Starts</div>
              {totalRaiseIsTBA ? (
                <div className="font-bold ml-2">TBA</div>
              ) : (
                <div className="font-bold ml-2">{startTime || 'TBA'}</div>
              )}
            </div>
          )}

          {project.starts && (
            <div className="mb-4 flex justify-between items-center">
              <div className={classes.projectCardDescription}>Starts</div>
              <div className="font-bold ml-2">{project.starts}</div>
            </div>
          )}

          {project.status === CampaignStatusEnum.Live &&
            displayStatus === 'Active' && (
              <>
                {project.type === CampaignTypeEnum.IDO ? (
                  <div
                    className="py-2"
                    style={{ borderTop: '1px solid #3C3C3E' }}
                  >
                    <TotalRaiseProgress
                      className="my-2"
                      variant="determinate"
                      value={Math.round(
                        parseInt(
                          ((project.saleStatus.targetRaise -
                            project.saleStatus.remainQuota) /
                            project.saleStatus.targetRaise) *
                            100,
                        ),
                      )}
                    ></TotalRaiseProgress>
                    <div className="text-right">
                      {parseFloat(
                        (
                          ((project.saleStatus.targetRaise -
                            project.saleStatus.remainQuota) /
                            project.saleStatus.targetRaise) *
                          100
                        ).toFixed(1),
                      )}
                      %
                    </div>
                  </div>
                ) : (
                  <div
                    className="py-2"
                    style={{ borderTop: '1px solid #3C3C3E' }}
                  >
                    <TotalRaiseProgress
                      className="my-2"
                      variant="determinate"
                      value={parseInt(progressPercent)}
                    ></TotalRaiseProgress>
                    <div className="text-right">{progressPercent}%</div>
                  </div>
                )}
              </>
            )}
          {project.status !== CampaignStatusEnum.Finished && (
            <Button
              disabled={!allowToViewCampaignDetail}
              className={classes.btnLearnMore}
            >
              Participate NOW
            </Button>
          )}
        </div>
      </>
    );

    const combinedClassName = useMemo(
      () =>
        clsx(
          'project-card bg-gray-900 rounded-lg p-6 relative',
          classes.projectCard,
          className,
          !project.disable_to_view_details && classes.link,
        ),
      [project.disable_to_view_details],
    );

    return allowToViewCampaignDetail ? (
      <a className={combinedClassName} href={campaignUrl} onClick={onClick}>
        {renderContent()}
      </a>
    ) : (
      <div className={combinedClassName}>{renderContent()}</div>
    );
  },
);
