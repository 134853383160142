import '../../styles/loading.scss';

export const Loading = (props) => {
  return (
    <div id='loader-wrapper' className='d-none'>
      <div className='loader-section section-left'></div>
      <div className='loader-section section-right'></div>
      <div id='loader-img'></div>
      <div id='loader-text'>{props.text}</div>
    </div>
  );
};
