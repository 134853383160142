import { store } from "reducers";
import { signOut } from "reducers/profileSlice";

const onSignout = () => {
  store.dispatch(signOut());
  setTimeout(() => {
    document.location.reload();
  }, 0);
};

export const configurableFetch = (
  input: RequestInfo | URL,
  init?: RequestInit
) =>
  fetch(input, init)
    .then((response: any) => {
      if (response.status === 401) {
        onSignout();
        if (document.location.pathname !== "/profile") {
          document.location.reload();
        }
        else {
          document.location.href = '/';
        }
        return Promise.reject(response);
      }
      return response;
    });
