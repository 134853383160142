import { getAuthApi } from "swagger";

export const connect = (addr) => {
  return getAuthApi()
    .apiAuthUserNonceAddrGet({
      addr,
    })
    .then((res) => res.json());
};

export const login = (body) => {
  return getAuthApi()
    .apiAuthUserLoginPost(body)
    .then((res) => res.json());
};
