export const API_URL =
  process.env.REACT_APP_API_URL || 'https://api-lp.spores.dev';
export const ADDRESS_RECEIVER =
  process.env.REACT_APP_ADDRESS_RECEIVER ||
  '0x063E6329BDeA016e110dcBCA76A24c0F50b13e38';
export const NETWORK_CHAIN_ID =
  process.env.REACT_APP_NETWORK_CHAIN_ID || '0x61';

export const LAUNCHPAD_CONTRACT =
  process.env.REACT_APP_LAUNCHPAD_CONTRACT ||
  '0xA1e3fdEC9FA3Fb49B68ae1EB6195E5207AF7a06F';

export const STAKING_URL =
  process.env.REACT_APP_STAKING_URL || 'https://staking.spores.app/';

export const IS_TESTNET =
  process.env.REACT_APP_IS_TESTNET === 'true';

export const PUBLIC_URL =
  process.env.REACT_APP_PUBLIC_URL || '/';
