export enum CampaignTimeTypeEnum {
  REGISTRATION = 'Registration',
  CAMPAIGN_TIME = 'Campaign time',
  VIP_ROUND = 'VIP Round',
  DOUBLE_QUOTA_ROUND = 'Double Quota Round',
  TRIPLE_QUOTA_ROUND = 'Triple Quota Round',
  FCFS_ROUND = 'FCFS Round',
}

export type IRound = {
  type: CampaignTimeTypeEnum;
  startTime: string | null;
  endTime: string | null;
}

