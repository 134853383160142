import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { usePopover } from 'hooks';
import {
  ListItemButton,
  Popover,
  Snackbar,
  IconButton,
  Alert,
} from '@mui/material';
import { connectWallet } from 'reducers/profileAction';
import { signOut } from 'reducers/profileSlice';
import { shorten } from 'utils/common';
import CloseIcon from '@mui/icons-material/Close';
import React, { useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Menu,
  MenuItem,
  Modal,
  styled,
} from '@mui/material';
import { Close, ExpandMore, Menu as MenuIcon } from '@mui/icons-material';
import { useAnchor } from 'hooks';
import { DarkButton, LazyImage } from 'components/Common';

const CustomMenuItem = styled(MenuItem)`
  font-weight: 500;
  font-size: 16px;
  color: #475467;
`;

const productList = [
  { title: 'Launchpad', url: 'https://launchpad.spores.app/' },
  { title: 'Marketplace', url: 'https://marketplace.spores.app/' },
];

const aboutList = [
  { title: 'About us', url: '/about' },
  {
    title: 'Docs',
    url: 'https://spores-network.gitbook.io/spores-network-white-paper-2.0/',
  },
];

const ourServiceList = [
  {
    name: 'Marketing',
    url: '',
    list: [
      {
        title: 'CMO services',
        content:
          'Take care of your whole marketing strategy with a dedicated and experienced team in the cryptocurrency business.',

        icon: 'icon-megaphone',
      },
      {
        title: 'Community',
        content:
          'Entirely build and manage your social community in order to cultivate long-term relationships with your target audiences.',
        icon: 'icon-users',
      },
      {
        title: 'KOL Booking',
        content: 'Create buzz around your project with high engagement KOLs',
        icon: 'icon-star',
      },
      {
        title: 'Shilling',
        content:
          'Post organic impressions and engagement on your social media with well-trained shillers that use customized scripts and strategies',
        icon: 'icon-coin-stack',
      },
    ],
  },
  {
    name: 'Development',
    url: '',
    list: [
      {
        title: 'Blockchain Development',
        content:
          'Public & Private Blockchain: We offer all kinds of full-stack blockchain development, including tailoring public or private platform chain with protocols that fit your needs.',
        icon: 'icon-3-layers',
      },
      {
        title: 'Gamefi Development',
        content:
          'Effortlessly launch your blockchain game with our ready-made, efficient and seamless game transition solution',
        icon: 'icon-controller',
      },
      {
        title: 'Whitelabel Launchpad & NFT Marketplace',
        content: 'NFT marketplace and Launchpad platforms with full features',
        icon: 'icon-target',
      },
      {
        title: 'Senior IT Personnel',
        content:
          'We provide dedicated & experience resources that work directly under your instructions.',
        icon: 'icon-git-pull-request',
      },
    ],
  },
];

const dropDownSetting = {
  sx: {
    overflow: 'visible',
    backgroundColor: '#FAFAFA',
    border: '',
    marginTop: 0,
    boxShadow:
      '0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    '&:before': {
      display: 'none',
    },
  },
};

const Header = () => {
  const [anchorElProduct, openProduct, onOpenProduct, onCloseProduct] =
    useAnchor();
  const [anchorElAbout, openAbout, onOpenAbout, onCloseAbout] = useAnchor();
  const [
    anchorElOurServices,
    openOurServices,
    onOpenOurServices,
    onCloseOurServices,
  ] = useAnchor();
  const [openPopup, setOpenPopup] = useState(false);

  const handleClosePopop = () => {
    setOpenPopup(false);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn, address } = useSelector(({ profile }) => profile);
  const [openMessage, setOpenMessage] = React.useState(false);
  const handleClick = () => {
    setOpenMessage(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenMessage(false);
  };

  const [isOpen, anchor, onClick, onClose] = usePopover();

  const onOrderHistoryClicked = () => {
    // TODO: update url by project id
    onClose();
    setTimeout(() => {
      navigate(`/user/order-history`);
    }, 0);
  };

  const onProfileClicked = () => {
    onClose();
    setTimeout(() => {
      navigate(`/user/profile`);
    }, 0);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const menuList = useMemo(
    () => [
      {
        title: 'PRODUCT',
        url: null,
        handleClick: onOpenProduct,
        list: productList,
      },
      // { title: 'NEWS', url: '/', handleClick: null, list: null },
      // { title: 'AIRDROP', url: '/airdrop', handleClick: null, list: null },
      {
        title: 'STAKING',
        url: 'https://staking.spores.app/',
        handleClick: null,
        list: null,
      },
      {
        title: 'AIRDROP',
        url: '/airdrop',
        handleClick: null,
        list: null,
      },
      // {
      //   title: 'OUR SERVICES',
      //   url: null,
      //   handleClick: onOpenOurServices,
      //   list: ourServiceList,
      // },
      // { title: 'ABOUT', url: null, handleClick: onOpenAbout, list: aboutList },
    ],
    [onOpenAbout, onOpenOurServices, onOpenProduct],
);

  return (
    <div
      className="flex justify-center fixed top-0 left-0 right-0 bg-[#141416] py-3 px-4"
      style={{ borderBottom: '1px solid #2F3033', zIndex: 100 }}
    >
      <div className="flex justify-between max-w-[1280px] w-full">
        <Link to="/">
          <img
            src={require('assets/icons/logo-spores-invert.svg').default}
            className="h-8 sm:h-10"
            alt="logo"
          />
        </Link>

        <>
          <div className="hidden lg:flex gap-2">
            {menuList.map((menu, index) => (
              <Button
                key={index}
                variant="text"
                className="text-base text-[#E5E7EB] hover:text-[#D9AC63] font-semibold"
                endIcon={!menu.url && <ExpandMore />}
                onClick={
                  !menu.url
                    ? menu.handleClick
                    : () => window.open(menu.url, '_blank')
                }
              >
                {menu.title}
              </Button>
            ))}
            {/* <DarkButton
              onClick={() => {
                window.open('https://t.me/hoaha47', '_blank');
              }}
            >
              Contact Us
            </DarkButton> */}
            <div className="flex items-center">
              {!isLoggedIn ? (
                <DarkButton onClick={connectWallet}>Connect wallet</DarkButton>
              ) : (
                <div>
                  <DarkButton onClick={onClick}>{shorten(address)}</DarkButton>
                  {isOpen && (
                    <Popover disableScrollLock open={isOpen} anchorEl={anchor} onClose={onClose}>
                      <ListItemButton
                        className="text-sm font-medium"
                        onClick={onProfileClicked}
                        style={{ backgroundColor: '#344054' }}
                      >
                        Profile
                      </ListItemButton>
                      <ListItemButton
                        className="text-sm font-medium"
                        onClick={onOrderHistoryClicked}
                        style={{ backgroundColor: '#344054' }}
                      >
                        Order history
                      </ListItemButton>
                      <ListItemButton
                        style={{ backgroundColor: '#344054' }}
                        className="text-sm font-medium"
                        onClick={() => {
                          handleClick();
                          onClose();
                          navigator.clipboard.writeText(address);
                        }}
                      >
                        Copy Address
                      </ListItemButton>
                      <ListItemButton
                        style={{ backgroundColor: '#344054' }}
                        className="text-sm font-medium"
                        onClick={() => {
                          onClose();
                          dispatch(signOut());
                        }}
                      >
                        Disconnect
                      </ListItemButton>
                    </Popover>
                  )}
                </div>
              )}
            </div>
          </div>
          <Menu
            anchorEl={anchorElProduct}
            disableScrollLock
            PaperProps={dropDownSetting}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            open={openProduct}
            onClose={onCloseProduct}
            onClick={onCloseProduct}
          >
            {productList.map((info, index) => (
              <CustomMenuItem
                key={index}
                onClick={() => {
                  window.open(info.url, '_blank');
                }}
              >
                {info.title}
              </CustomMenuItem>
            ))}
          </Menu>
          <Menu
            anchorEl={anchorElAbout}
            disableScrollLock
            PaperProps={dropDownSetting}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            open={openAbout}
            onClose={onCloseAbout}
            onClick={onCloseAbout}
          >
            {aboutList.map((info, index) => (
              <CustomMenuItem
                key={index}
                onClick={() => {
                  window.open(info.url, '_blank');
                }}
              >
                {info.title}
              </CustomMenuItem>
            ))}
          </Menu>
          <Menu
            anchorEl={anchorElOurServices}
            disableScrollLock
            PaperProps={dropDownSetting}
            transformOrigin={{ horizontal: 'center', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            open={openOurServices}
            onClose={onCloseOurServices}
            onClick={onCloseOurServices}
          >
            <div className="grid grid-cols-2 gap-9 max-w-[1320px] py-6 px-12">
              {ourServiceList.map((item, index) => (
                <MenuItem
                  className="rounded-md flex flex-col items-start gap-4"
                  key={index}
                  // onClick={() => {
                  //   window.open(item.url, '_blank');
                  // }}
                >
                  <div className="text-sm text-[#AA834C] font-semibold mb-4">
                    {item.name}
                  </div>
                  <div className="flex flex-col gap-2">
                    {item.list.map((info, index) => (
                      <div key={index}>
                        <div className="flex gap-4">
                          <img
                            src={`/assets/images/icons/${info.icon}.png`}
                            className="h-6 w-6"
                          />
                          <div>
                            <div className="text-[#101828] font-semibold mb-1">
                              {info.title}
                            </div>
                            <div className="whitespace-normal text-[#667085] text-sm">
                              {info.content}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </MenuItem>
              ))}
            </div>
          </Menu>
        </>

        <>
          <IconButton
            className="block lg:hidden text-white"
            onClick={() => setOpenPopup(true)}
          >
            <MenuIcon />
          </IconButton>
          <Modal open={openPopup} onClose={handleClosePopop}>
            <div className="flex justify-end h-full">
              <div
                className="w-full p-4 overflow-auto"
                style={{ background: '#fff', maxWidth: 575 }}
              >
                <div className="flex justify-end">
                  <IconButton onClick={handleClosePopop}>
                    <Close className="text-[#667085]" />
                  </IconButton>
                </div>
                <div className="flex flex-col gap-2">
                  {menuList.map((menu, index) =>
                    menu.list ? (
                      <Accordion
                        key={index}
                        className="text-2xl font-black bg-none bg-transparent shadow-none m-0"
                        sx={{ '&:before': { backgroundColor: 'transparent' } }}
                      >
                        <AccordionSummary
                          className="bg-none bg-transparent text-base text-[#667085] font-semibold px-2"
                          expandIcon={
                            <ExpandMore className="ml-2 text-[#667085]" />
                          }
                        >
                          {menu.title}
                        </AccordionSummary>
                        <AccordionDetails className="bg-none bg-transparent">
                          {menu.title === 'OUR SERVICES' ? (
                            <div className="flex flex-col gap-9">
                              {menu.list.map((listItem, index) => (
                                <div
                                  key={index}
                                  className="flex flex-col gap-4"
                                >
                                  <div className="font-semibold text-sm text-[#B89254] px-2 py-1.5">
                                    {listItem.name}
                                  </div>
                                  {listItem.list.map((listItem2, index) => (
                                    <div
                                      key={index}
                                      variant="text"
                                      className="text-base text-[#667085] font-semibold justify-start px-2 py-1.5"
                                      // onClick={() => window.open(listItem2.url, '_blank')}
                                    >
                                      {listItem2.title}
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="flex flex-col gap-4">
                              {menu.list.map((listItem, index) => (
                                <Button
                                  key={index}
                                  variant="text"
                                  className="text-base text-[#667085] font-semibold justify-start"
                                  onClick={() =>
                                    window.open(listItem.url, '_blank')
                                  }
                                >
                                  {listItem.title}
                                </Button>
                              ))}
                            </div>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      <Button
                        key={index}
                        variant="text"
                        className="text-base text-[#667085] font-semibold justify-start"
                        onClick={() => window.open(menu.url, '_blank')}
                      >
                        {menu.title}
                      </Button>
                    ),
                  )}
                  {/* <DarkButton
                    onClick={() => {
                      window.open('https://t.me/hoaha47', '_blank');
                    }}
                  >
                    Contact Us
                  </DarkButton> */}
                  <div className="flex items-center">
                    {!isLoggedIn ? (
                      <DarkButton onClick={connectWallet} className='w-full'>
                        Connect wallet
                      </DarkButton>
                    ) : (
                      <div>
                        <DarkButton onClick={onClick} className='w-full'>
                          {shorten(address)}
                        </DarkButton>
                        {isOpen && (
                          <Popover
                            open={isOpen}
                            anchorEl={anchor}
                            onClose={onClose}
                          >
                            <ListItemButton
                              className="text-sm"
                              onClick={onProfileClicked}
                              style={{ backgroundColor: '#344054' }}
                            >
                              Profile
                            </ListItemButton>
                            <ListItemButton
                              className="text-sm"
                              onClick={onOrderHistoryClicked}
                              style={{ backgroundColor: '#344054' }}
                            >
                              Order history
                            </ListItemButton>
                            <ListItemButton
                              style={{ backgroundColor: '#344054' }}
                              className="text-sm"
                              onClick={() => {
                                handleClick();
                                onClose();
                                navigator.clipboard.writeText(address);
                              }}
                            >
                              Copy Address
                            </ListItemButton>
                            <ListItemButton
                              style={{ backgroundColor: '#344054' }}
                              className="text-sm"
                              onClick={() => {
                                onClose();
                                dispatch(signOut());
                              }}
                            >
                              Disconnect
                            </ListItemButton>
                          </Popover>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>
        <Snackbar
          className="bg-white"
          open={openMessage}
          autoHideDuration={2000}
          onClose={handleClose}
          action={action}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            Copied!
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default Header;
