import { Typography } from '@mui/material';
import React, { memo } from 'react';

import { NewProjectCard } from './NewProjectCard';

export const Upcoming: React.NamedExoticComponent<{
  projects: any[];
}> = memo(({ projects = [] }) => {
  return (
    <div className='upcoming'>
      <Typography variant='h2' className='mb-6'>
        Upcoming
      </Typography>
      <div className='grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8'>
        {projects.map((project, index) => (
          <NewProjectCard key={index} project={project} displayStatus={'Upcoming'} />
        ))}
      </div>
    </div>
  );
});
