import { Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as GateIO } from 'assets/projects/animalia/GateIO.svg';
import { ReactComponent as Uniswap } from 'assets/projects/animalia/Uniswap.svg';
import { STAKING_URL } from 'env';

export const LinkCustom = styled(Link)(({ theme }) => ({
  '&:hover': {
    color: '#cfb675',
  },
  color: '#ffffff',
  cursor: 'pointer',
}));

export const LinkCustomBtn = styled(LinkCustom)(({ theme }) => ({
  '&:hover': {
    color: '#ffffff',
    backgroundColor: 'rgb(164, 165, 168)',
  },
}));

function PreHeader() {
  return (
    <div
      className="h-16 px-6 hidden lg:flex items-center text-white"
      style={{
        backgroundColor: '#232323',
        boxShadow: 'inset 0px -0.5px 0px rgba(255, 255, 255, 0.25)',
      }}
    >
      <div className="flex-grow flex">
        <span className="mr-3 text-sx xl:text-base">Ethereum Contract:</span>
        <LinkCustom
          href="https://etherscan.io/token/0xcbE771323587EA16dACB6016e269D7F08A7ACC4E"
          target={'_blank'}
        >
          0xcbE7713235...F08A7ACC4E
        </LinkCustom>
        <span className="mx-5">|</span>
        <span className="mr-3 text-sx xl:text-base">BSC Contract:</span>
        <LinkCustom
          href="https://bscscan.com/token/0x8357c604c5533fa0053beaaa1494da552cea38f7"
          target={'_blank'}
        >
          0x8357c604c5...552cea38f7
        </LinkCustom>
      </div>
      <div className="flex items-center">
        <Typography className="mr-4 flex items-center">Get SPO:</Typography>
        <LinkCustom
          href="https://app.uniswap.org/#/swap?outputCurrency=0xcbE771323587EA16dACB6016e269D7F08A7ACC4E"
          target={'_blank'}
          className="flex"
        >
          <Uniswap className="mr-2 items-center" />
        </LinkCustom>
        <LinkCustom
          href="https://app.uniswap.org/#/swap?outputCurrency=0xcbE771323587EA16dACB6016e269D7F08A7ACC4E"
          target={'_blank'}
          className="flex mr-8"
        >
          {' '}
          Uniswap
        </LinkCustom>
        <LinkCustom
          href="https://www.gate.io/trade/SPO_USDT"
          target={'_blank'}
          className="flex"
        >
          <GateIO className="mr-2 items-center" />
        </LinkCustom>
        <LinkCustom
          href="https://www.gate.io/trade/SPO_USDT"
          target={'_blank'}
          className="flex mr-8"
        >
          Gate.io
        </LinkCustom>
        <LinkCustomBtn
          target={'_blank'}
          href={STAKING_URL}
          className="xl:rounded-full rounded-3xl xl:border-2 border-[1px] border-white xl:px-4 xl:py-2 px-2 py-1 cursor-pointer xl:text-base text-xs truncate"
        >{`Stake & Earn`}</LinkCustomBtn>
      </div>
    </div>
  );
}

export default PreHeader;
