import React, { useState } from 'react';
import { Link } from '@mui/material';
import { getSPOList, shorten } from 'utils/utils';
import clsx from 'clsx';
import { HoverLogo } from 'components/Common';

const helpList = [
  // { text: 'FAQs', url: '' },
  {
    text: 'Apply to Launch',
    url: 'https://docs.google.com/forms/d/1K_GPDM6RmYbz60GWyEy9IKXUyEGBrRqlTnmqU8Gd1cw/viewform?edit_requested=true#settings',
  },
  // { text: 'About', url: '/about' },
  {
    text: 'Docs',
    url: 'https://spores-network.gitbook.io/spores-network-white-paper-2.0/',
  },
  // { text: 'News', url: '' },
  { text: 'Contact us', url: 'https://t.me/hoaha47', className: 'text-white px-3 py-1 rounded border border-solid border-white hover:border-[#eece7c]' },
];

const productList = [
  { text: 'Launchpad', url: 'https://launchpad.spores.app' },
  { text: 'Staking', url: 'https://staking.spores.app/' },
  { text: 'Earning', url: 'https://staking-v1.spores.app/' },
  { text: 'Marketplace', url: 'https://marketplace.spores.app' },
  { text: 'République', url: 'https://republique.spores.app/' },
  { text: 'Gamestore', url: 'https://games.spores.app' },
];

const contractList = [
  {
    text: 'Ethereum Contract',
    address: '0xcbE771323587EA16dACB6016e269D7F08A7ACC4E',
    url: 'https://etherscan.io/token/0xcbE771323587EA16dACB6016e269D7F08A7ACC4E',
  },
  {
    text: 'BSC Contract',
    address: '0x8357c604c5533fa0053beaaa1494da552cea38f7',
    url: 'https://bscscan.com/token/0x8357c604c5533fa0053beaaa1494da552cea38f7',
  },
];

const mediaList = [
  { image: 'twitter', url: 'https://twitter.com/Spores_Network' },
  { image: 'telegram', url: 'https://t.me/SporesOfficial' },
  { image: 'medium', url: 'https://spores.medium.com/' },
  { image: 'facebook', url: 'https://facebook.com/SporesNetwork' },
  { image: 'linkedin', url: 'https://linkedin.com/company/SporesNetwork' },
  // { image: 'instagram', url: 'https://instagram.com/SporesNetwork' },
  // { image: 'discord', url: 'https://discord.gg/mCTU9kx6' },
];

const HoverLogoMedia: React.FC<{
  image: string;
}> = ({ image }) => {
  const [color, setColor] = useState('#667085');

  return (
    <div
      className="cursor-pointer w-fit"
      onMouseEnter={() => setColor('#FFE89E')}
      onMouseLeave={() => setColor('#667085')}
    >
      <i
        className={`block w-6 h-6`}
        style={{
          WebkitMaskImage: `url(/assets/imgs/socials/${image}.png)`,
          WebkitMaskSize: '100%',
          background: color,
        }}
      />
    </div>
  );
};

const Title: React.FC<{
  text: string;
}> = ({ text }) => (
  <div className="text-white text-xl font-semibold mb-7">{text}</div>
);

const CustomText: React.FC<{
  text: string;
  className?: string;
}> = ({ className, text }) => (
  <div className={clsx(className, "text-[#A2A8B3] hover:text-[#EECE7C]")}>{text}</div>
);

const Footer = () => {
  return (
    <div
      className="px-4 py-10 bg-[#141416] flex justify-center"
      style={{ borderTop: '1px solid #2F3033' }}
    >
      <div className="max-w-[1320px] w-full text-[#667085]">
        <div className="grid grid-cols-2 md:grid-cols-6 gap-10 pb-8 mb-8 border-solid border-b-[1px] border-[#2F3033]">
          <div className="col-span-2 border-solid border-b-[1px] md:border-b-0 md:border-r-[1px] border-[#2F3033] pb-8">
            <img
              src={require('assets/icons/logo-spores-invert.svg').default}
              className="h-10 mb-[18px]"
              alt="logo"
            />
            <div className="pr-10 text-[#A2A8B3]">
              Spores Network is the leading multi-chain launchpad for IDOs and
              INOs of up-and-coming high quality web3 projects, in which user
              can seamlessly participate & support the project!
            </div>
          </div>
          <div>
            <Title text="Product" />
            <div className="flex flex-col gap-2">
              {productList.map((item, index) => (
                <div key={index} className="flex">
                  <Link href={item.url} target="_blank">
                    <CustomText text={item.text} />
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div>
            <Title text="Token" />
            <div className="flex flex-col gap-4">
              {getSPOList.map((item, index) => (
                <div key={index} className="flex">
                  <Link href={item.url} target="_blank">
                    <HoverLogo image={item.image} isButton={false} />
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div>
            <Title text="Help Center" />
            <div className="flex flex-col gap-2">
              {helpList.map((item, index) => (
                <div key={index} className="flex">
                  <Link href={item.url} target="_blank">
                    <CustomText className={item.className} text={item.text} />
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div>
            <Title text="Contact" />

            <div className="grid grid-cols-3 gap-6 max-w-[117px]">
              {mediaList.map((item, index) => (
                <div key={index}>
                  <Link href={item.url} target="_blank">
                    <HoverLogoMedia image={item.image} />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between text-sm break-words gap-4">
          <div className="text-sm break-words flex flex-col md:flex-row gap-4 md:gap-10">
            {contractList.map((item, index) => (
              <div key={index}>
                <Link href={item.url} target="_blank">
                  <CustomText text={`${item.text}: ${shorten(item.address)}`} />
                </Link>
              </div>
            ))}
          </div>
          <div>customersupport@spores.app</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
