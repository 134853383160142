import { web3 } from 'contracts/contract';

export const formatNumber = (
  number: number,
  prefix: string = '',
  fractionDigits = -1,
) => {
  const beforeDecimalPoint = number.toString().split('.')[0];
  let afterDecimalPoint = number.toString().split('.')[1];
  if (fractionDigits >= 0) {
    afterDecimalPoint = afterDecimalPoint.slice(0, fractionDigits);
  }
  const hasAfterDecimalPoint = !!afterDecimalPoint;
  const localeBeforeDecimalPoint = Number(beforeDecimalPoint).toLocaleString();
  return (
    (!prefix
      ? localeBeforeDecimalPoint
      : `${prefix}${localeBeforeDecimalPoint}`) +
    (hasAfterDecimalPoint ? `.${afterDecimalPoint}` : '')
  );
};
export const decimalFormat = (number: any) =>
  web3.utils.fromWei(number.toString(16));

export const formatNumberForVesting = (number: number) =>
  Number((Math.floor(number * Math.pow(10, 3)) / Math.pow(10, 3)).toFixed(3)).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  });

export const twoDecimalFormat = (number: number) =>
  parseFloat(number.toFixed(2));

export const shorten = (address: string, head = 6, tail = 4) => {
  if (typeof address !== 'string') return address;
  return address.slice(0, head) + '...' + address.slice(address.length - tail);
};

export * from './formatTwoDigits';
export * from './isNumeric';
export * from './date';
export * from './lazyLoadThenCreateComponent';

export * from './formatAddress';
export * from './getAllMethodNames';
export * from './getAllPrototypeMethodNames';
export * from './removeEmptyProperties';
export * from './to-json';
export * from './isUSDStableToken';
export * from './formatHTMLYoutubeEmbed';
