import { SnackbarProvider } from 'notistack';

const anchorOriginOptions = {
  vertical: 'top',
  horizontal: 'right',
};
const snackbarStyle = { marginTop: 60 };

export const withSnackbar = (Component) => (props) =>
  (
    <SnackbarProvider
      preventDuplicate
      anchorOrigin={anchorOriginOptions}
      style={snackbarStyle}
    >
      <Component {...props} />
    </SnackbarProvider>
  );
