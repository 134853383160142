import { createSlice } from '@reduxjs/toolkit';
import { login } from 'utils/auth';

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    isLoggedIn: false,
    currentChainId: undefined,
  },
  reducers: {
    signIn: (state, { payload }) => {
      state = { ...state, ...payload, isLoggedIn: true };
      localStorage.setItem('profile', JSON.stringify(state));
      login(payload);
      return state;
    },
    signOut: (state) => {
      state = { isLoggedIn: false };
      localStorage.clear();
      return state;
    },
    setCurrentChainId: (state, { payload }) => {
      state = { ...state, currentChainId: payload.chainId };
      return state;
    },
  },
});

export const { signIn, signOut, setCurrentChainId } = profileSlice.actions;

export const selectCurrentChainId = state => state.currentChainId

export default profileSlice.reducer;
