import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'

import { store, persistor } from "reducers";

export const withRedux = (Component) => (props) =>
  (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Component {...props} />
      </PersistGate>
    </ReduxProvider>
  );
